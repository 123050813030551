import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';


if(environment.production) {
  console.log('Production mode');
  enableProdMode();
} else {
  console.log('Development mode');
}

import('./bootstrap')
	.catch(err => console.error(err));
